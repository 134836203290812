<template>
    <div>
        <div v-if="!isLargeViewport" class="py-0 my-0" @click="toggleAccordion" style="cursor: pointer">
            <CustomIcon v-if="!isDisplayFilterOpen" :size="24" color="green" glyph="mdi-chevron-down" />
            <CustomIcon v-if="isDisplayFilterOpen" :size="24" color="green" glyph="mdi-chevron-up" />
            Filters
        </div>
        <div class="pl-3" :class="['accordion-content', { open: isDisplayFilterOpen || isLargeViewport}]">
            <v-switch v-model="showPublicAccess" label="public access" @change="togglePublicAccess()" color="success" hide-details ></v-switch>
            <v-switch v-model="showFriendAccess" label="friend access" @change="toggleFriendAccess()" color="success" hide-details ></v-switch>
            <v-switch v-model="showPrivate" label="private" @change="togglePrivate()" color="success" hide-details ></v-switch>
            <p class="mt-4" style="font-size: .8em;"> Showing {{ filteredListsCount }}</p>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
    },
    data() {
        return {
            isDisplayFilterOpen: false,
            showFriendAccess: true,
            showPrivate: true,
            showPublicAccess: true,
        };
    },
    computed: {
        isLargeViewport() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
    },
    watch: {
        // Watching for changes in the 'watchedProperty' data property
        watchedProperty(newValue, oldValue) {
            console.log("Value changed from", oldValue, "to", newValue);
        },
    },
    mounted() {
    },
    methods: {
        toggleAccordion() {
            this.isDisplayFilterOpen = !this.isDisplayFilterOpen;
        }
    },
    props: ['toggleFriendAccess','togglePrivate','togglePublicAccess','filteredListsCount'],
};
</script>
<style scoped>
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-out;
}

@media (min-width: 768px) {
    .accordion-content.open {
        max-height: auto;
    }
}
.open {
    max-height: 500px;
}
</style>